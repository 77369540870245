import React from "react"
import { graphql } from "gatsby"
import Styled from "styled-components"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"

const Faqs = ({ data }) => {
  const headerSecondary = data.secondary.edges[0].node.childImageSharp.fluid
  return (
    <Layout img={headerSecondary}>
      <SEO
        title="Education"
        keywords={["Education", "Beyond The Apprenticeship", "Matt Pehrson"]}
      />
      <div className="row">
        <div className="col-md-12">
          <h1>Faqs</h1>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FaqsQuery {
    secondary: allFile(filter: { name: { eq: "kd_logo_white_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Faqs
